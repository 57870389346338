import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WarningIcon from '@material-ui/icons/Warning';
import WebIcon from '@material-ui/icons/Web';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Route, Link, Switch } from 'react-router-dom';

import { animateScroll } from 'react-scroll';

export const drawerWidth = 240;

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  listContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  navList: {
    paddingBottom: 0,
  },
  madeBy: {
    paddingTop: 0,
  },
  drawerPaper: {
    marginRight: drawerWidth,
    width: drawerWidth,
  },
  activeText: {
    color: theme.palette.primary.main,
  },
});

class ButtonComponent extends React.Component {
  handleClick = () => {
    const { toggleDrawer } = this.props;

    animateScroll.scrollToTop({ duration: 0 });
    toggleDrawer(false)();
  };

  render() {
    const { classes, route } = this.props;

    return (
      <ListItem
        component={Link}
        to={route.path}
        button
        onClick={this.handleClick}
      >
        <ListItemIcon>
          {route.icon ? <route.icon /> : <WarningIcon />}
        </ListItemIcon>
        <Switch>
          <Route
            path={route.path}
            exact={route.exact}
            render={() => (
              <ListItemText
                primary={route.title}
                secondary={route.subtitle}
                classes={{ primary: classes.activeText }}
              />
            )}
          />
          <Route
            render={() => (
              <ListItemText primary={route.title} secondary={route.subtitle} />
            )}
          />
        </Switch>
      </ListItem>
    );
  }
}
const StyledButtonComponent = withStyles(styles)(ButtonComponent);

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
class Sidebar extends React.Component {
  render() {
    const { classes, routes, toggleDrawer, open } = this.props;

    const contents = (
      <div className={classes.listContainer}>
        <List className={classes.navList}>
          {routes.map((route, index) => (
            <React.Fragment key={index}>
              <StyledButtonComponent
                key={index}
                route={route}
                toggleDrawer={toggleDrawer}
              />
              {route.divider && <Divider />}
            </React.Fragment>
          ))}
        </List>
        <List className={classes.madeBy}>
          <Divider />

          <ListItem
            button
            href="https://fruitiex.surge.sh"
            target="_blank"
            component="a"
          >
            <ListItemIcon>
              <WebIcon />
            </ListItemIcon>
            <ListItemText primary="Web design" secondary="Rasmus Eskola" />
          </ListItem>
        </List>
      </div>
    );

    return (
      <React.Fragment>
        <Hidden mdUp>
          <SwipeableDrawer
            disableDiscovery={iOS}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {contents}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {contents}
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Sidebar);
