import styled from 'styled-components';

export const EmbeddedMedia = styled.div`
  margin: 16px;
  width: ${props => props.width};
  height: ${props => props.height};
  float: ${props => props.float};
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;
