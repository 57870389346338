import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import { scroller } from 'react-scroll';

import { MedialisGrid, MedialisGridContainer, Bullet } from './Components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Massage from './assets/massage.jpg';
import Kinesio from './assets/kinesio.jpg';
import Kulho from './assets/kulho.jpg';
import Mobilisoiva from './assets/mobilisoiva.jpg';

const styles = theme => ({
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
  media_expansion: {
    height: 200,
    backgroundSize: 'cover',
    marginTop: -8,
    marginLeft: -24,
    marginRight: -24,
  },
  nestedList: {
    paddingLeft: theme.spacing.unit * 2,
  },
});

class Palvelu extends React.Component {
  render() {
    const {
      type,
      active,
      primary,
      secondary,
      handleClick,
      children,
    } = this.props;

    return (
      <ExpansionPanel
        id={type}
        onChange={handleClick(type)}
        expanded={active === type}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <ListItemText primary={primary} secondary={secondary} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List disablePadding>{children}</List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

class Palvelut extends React.Component {
  state = { active: null };

  scrollTimeout = null;

  clearScrollTimeout = e => console.log(e) || clearTimeout(this.scrollTimeout);

  componentDidMount = () => {
    // Clear scroll timeout if user scrolls
    // TODO: unfortunately this is also fired when browser auto scrolls :(
    // window.addEventListener('scroll', this.clearScrollTimeout);
  };

  componentWillUnmount = () => {
    // window.removeEventListener('scroll', this.clearScrollTimeout);
    clearTimeout(this.scrollTimeout);
  };

  handleClick = type => () => {
    this.setState(oldState => ({
      active: type === oldState.active ? null : type,
    }));

    clearTimeout(this.scrollTimeout);

    if (type !== this.state.active) {
      // delay scrolling by 400 ms so the section animations are done
      this.scrollTimeout = setTimeout(() => {
        const padding = 14;
        const appBarHeight =
          document.getElementById('appBar').clientHeight + padding;

        const elem = document.getElementById(type);
        const winHeight = window.innerHeight;

        const { top, bottom, height } = elem.getBoundingClientRect();

        let offset = -appBarHeight;

        // can the entire section fit into view at once? else just scroll to beginning of it
        if (height < winHeight - appBarHeight) {
          // scroll bottom into view
          if (bottom > winHeight) {
            offset = -top + (bottom - winHeight) + padding;
          }
        }

        // only scroll if section isn't already in view
        if (top < appBarHeight || bottom > winHeight) {
          scroller.scrollTo(type, {
            smooth: true,
            duration: 300,
            offset: offset,
            isDynamic: true,
          });
        }
      }, 700);
    }
  };

  render() {
    const { classes } = this.props;
    const { active } = this.state;

    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media_cover} image={Massage} />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Palvelut / Tjänster
                </Typography>

                <Typography color="textSecondary">
                  Saat lisätietoja klikkaamalla palvelua.
                </Typography>
                <Typography color="textSecondary">
                  Du får mera information genom att klicka på en tjänst.
                </Typography>
              </CardContent>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Palvelu
              type="hoitava"
              active={active}
              primary="Hoitava hieronta"
              secondary="Vårdande massage"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Tehokas hoitomuoto tuki- ja liikuntaelimistön sairaus- ja
                kiputiloissa.
              </Bullet>
              <Bullet>
                Myönteinen vaikutus esim. lihasjännitykseen, stressiin,
                unettomuuteen, jännityspäänsärkyyn.
              </Bullet>
              <Bullet>Lisää lihasten ja nivelten liikkuvuutta.</Bullet>
              <Bullet>
                Lisää aineenvaihduntaa, poistaa kuona-aineita ja vähentää
                turvotusta.
              </Bullet>
            </Palvelu>

            <Palvelu
              type="urheilu"
              active={active}
              primary="Urheiluhieronta"
              secondary="Idrottsmassage"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Lajiharjoitteluun sovellettua yksilöllistä hierontaa, huomioiden
                eri harjoitteiden ja rasitusten vaikutukset keholle
              </Bullet>
              <Bullet>
                Ennaltaehkäisee rasitusvammojen syntymistä ja lihaskireyttä
              </Bullet>
              <Bullet>Auttaa palautumisessa</Bullet>
              <Bullet>Edistää vammojen paranemisessa</Bullet>
              <Bullet>Rentouttaa ja poistaa stressiä</Bullet>
              <Bullet>Sopii myös aktiiviliikkujalle</Bullet>
            </Palvelu>

            <Palvelu
              type="mobilisoiva"
              active={active}
              primary="Mobilisoiva hieronta"
              secondary="Mobiliserande massage"
              handleClick={this.handleClick}
            >
              <CardMedia
                className={classes.media_expansion}
                image={Mobilisoiva}
                disablePadding
              />
              <Bullet first>
                Mobilisointi on liikehoitoa ja tehokas keino hoitaa tuki- ja
                liikuntaelinten toimintahäiriöitä. Mobilisointi auttaa kivun
                lievityksessä, lihakset rentoutuvat ja nivelliikkuvuus paranee.
                Hoidon aikana ei suoriteta nopeita nykäiseviä
                manipulaatioliikkeitä. Mobilisoivaa hierontaa käytetään
                tavallisen hieronnan yhteydessä ja hoito voidaan kohdistaa
                yksittäisiin ongelmiin aiheuttaviin niveliin ja niitä
                ympäröiviin syviin pehmytkudoksiin, kuten nivelkapseliin ja
                nivelsiteisiin.
              </Bullet>
              <Bullet first>
                Niveliä liikutettaessa pyritään saamaan aikaan liukumista
                nivelpintojen välillä ja traktiolla eli vetohoidolla saadaan
                nivelpinnat loitontumaan toisistaan, jolloin paine
                nivelpinnoilla vähenee ja nivelen aineenvaihdunta lisääntyy sekä
                kipu lievittyy.
              </Bullet>

              <Bullet first>
                Mobilisoivassa hieronnassa käytetään myös apuna liikettä ja
                kehon eri osien muodostamia vipuvarsia. Hoidolla pyritään
                lievittämään kipuja, normalisoimaan nikamien ja nivelten
                liikelaajuus, sekä korjaamaan virheasentoja. Mobilisoivaa
                hierontaa voidaan käyttää myös selkärangan eri osiin.
              </Bullet>
            </Palvelu>

            <Palvelu
              type="lihaskalvo"
              active={active}
              primary="Lihaskalvohieronta (fasciakäsittely)"
              secondary="Fasciabehandling"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Lihaskalvo on sidekudosta, joka ympäröi ja tukee kehon
                pehmytkudoksia. Lihaskalvot muodostavat päästä varpaisiin
                kulkevia lihaskalvolinjoja, jotka yhdistävät tuki- ja
                liikuntaelimistön osat toisiinsa. Lihaskalvot koostuvat ikään
                kuin ohuista verkoista jotka ovat limittäin, jos nämä verkot
                tukkeutuvat voidaan kuvitella villapaitaa, jonka yhtä lankaa
                aletaan vetämään.
              </Bullet>

              <Bullet first>
                Myofascial release on manuaalinen hoitotekniikka, jolla
                venytetään faskiarakenteita tavoitteena kehon tasapainottaminen.
                Kireydet fascioissa aiheuttavat kiputiloja, epätasapainoa ja
                toimintahäiriöitä lihas- ja niveltoiminnoissa, josta aiheutuu
                ryhtivirheitä, sekä kehon toimintahäiriöitä.
              </Bullet>
            </Palvelu>

            <Palvelu
              type="triggerpiste"
              active={active}
              primary="Triggerpistehieronta"
              secondary="Triggerpunktsbehandling"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Triggerpisteet ovat merkittävä tekijä lihasperäisten kipujen
                aiheuttajana. Triggerpiste on erittäin herkkä / ärtynyt piste
                tiukan lihassäikeen sisällä, mikä on herkistynyt kompressiolle,
                venytykselle ja kuormitukselle. Aktiivisessa tilassa ne
                aiheuttavat usein paikallista jomottavaa kipua, säteillen kipua
                usein myös kauemmaksi heijastealueelle, jota voi olla vaikea
                tarkkaan paikallistaa. Esim. kaulassa oleva triggerpiste voi
                heijastaa kipua päälaelle ja silmän ympärille sekä isossa
                rintalihaksessa oleva triggerpiste voi heijastaa kipua aina
                sormiin asti.
              </Bullet>

              <Bullet first>
                Hoidan triggerpisteitä hieronnan yhteydessä ensin
                systemaattisesti palpoimalla kudokset ja kipeimmän pisteen
                löydyttyä jatkan hoitoa painallustekniikalla, jolloin kipu
                vähenee n. 20 - 40 sekunnissa, tämän jälkeen voidaan käsitellä
                koko lihas hieromalla ja venytystekniikkaa käyttämällä.
              </Bullet>

              <Bullet first>
                <b>Hoidon aikaiset / jälkeiset mahdolliset reaktiot:</b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>
                  Käsiteltävä alue punoittaa ja voi esiintyä lievää turvotusta
                </Bullet>
                <Bullet>
                  Käsittelyn alussa muutaman sekunnin ajan, käsiteltävä alue voi
                  aristaa voimakkaasti
                </Bullet>
                <Bullet>
                  10 min. hoidon jälkeen paikallinen arkuus voi lisääntyä
                </Bullet>
                <Bullet>10-24h hoidosta inflammaation huippu</Bullet>
                <Bullet>
                  24-48h inflammaatiovaihe loppuu ja oireilu sen mukana
                </Bullet>
              </List>

              <Bullet first>
                <b>Alistavia tekijöitä</b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>
                  Lihasten ylikuormitus esim. rasitus toistuvan liikkeen
                  johdosta, ryhtivirhe tai huono ergonomia
                </Bullet>
                <Bullet>Trauma</Bullet>
                <Bullet>Lihastasapainon häiriö</Bullet>
                <Bullet>Yliliikkuvat nivelet</Bullet>
                <Bullet>Veto, kylmä ja kosteus</Bullet>
                <Bullet>Stressi</Bullet>
              </List>
            </Palvelu>

            <Palvelu
              type="kinesioteippaus"
              active={active}
              primary="Kinesioteippaus"
              secondary="Kinesiotejpning"
              handleClick={this.handleClick}
            >
              <CardMedia
                className={classes.media_expansion}
                image={Kinesio}
                disablePadding
                title="Arpiteippaus"
              />
              <ListItem>
                <Typography className={classes.pos} color="textSecondary">
                  Arpiteippaus
                </Typography>
              </ListItem>

              <Bullet first>
                Hyvä hoitomuoto kipuun, lihasten rauhoittamiseen sekä
                lepojännityksen tasapainotukseen, nivelten liikkuvuuden
                lisäämiseen ja tukemiseen, koordinaatioon-, ryhdin korjaukseen
                ja aineenvaihdunnan parantamiseen sekä arpikudoksen
                pehmentämiseen. Teippauksen voi tehdä hieronnan yhteydessä tai
                erikseen sovittuna aikana
              </Bullet>

              <Bullet first>
                <b>Teippausta kannattaa kokeilla esim. seuraaviin ongelmiin:</b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>Alaselkä- ja niskakipuun</Bullet>
                <Bullet>Raajojen turvotukseen (esim. raskauden aikana)</Bullet>
                <Bullet>
                  Arpikudokseen (vanhaankin arpikudokseen voidaan saada hyviä
                  hoitotuloksia), lihaskalvot pääsevät liukumaan vapaammin ja
                  liikelaajuus sekä kiristys helpottuu
                </Bullet>
                <Bullet>
                  Akillesjänteen kiputilaan, plantaarifaskiittiin eli
                  kantakalvon tulehdus, hyppääjän polveen, penikka ongelmiin,
                  tennis- ja golfkyynärpääongelmiin
                </Bullet>
                <Bullet>
                  Teippi on hengittävä ja sitä voi pitää suihkussa ja saunassa.
                  Teippi voi olla käytössä useita päiviä ellei iho ärsyynny.
                  Teippaus voidaan uusia tarpeen mukaan useita kertoja.
                </Bullet>
              </List>
            </Palvelu>

            <Palvelu
              type="jalkapohjien"
              active={active}
              primary="Jalkapohjien hieronta"
              secondary="Fotbottenmassage"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Oletko tullut ajatelleeksi jalkapohjiesi hierontaa, sillä se
                kannattaa. Jalkaterät ja nilkat ovat jokaisen ihmisen tärkeä
                perusta ja niiden hieronta on tärkeää kaikille, ennen kaikkea
                seisomatyötä tekeville.
              </Bullet>

              <Bullet first>
                Jalkaterän alueen ongelmat voivat aiheuttaa selkä- ja
                polvikipuja esim. nilkan nyrjähdys voi vaikuttaa nilkkanivelen
                liikkuvuuteen ja kireästä lihaksistosta voi muodostua
                kiputiloja. Hieronnassa käsitellään koko jalkaterä ja nilkka eri
                hieronta-, venytys- ja mobilisaatiotekniikoin.
              </Bullet>
            </Palvelu>

            <Palvelu
              type="puremalihashieronta"
              active={active}
              primary="Puremalihashieronta"
              secondary="Tuggmuskulatursbehandling"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Purentaongelmat ovat yleisiä ja niistä voi aiheutua useita
                erilaisia vaivoja. Hampaiden runsas kuluminen ja paikkojen
                lohkeilu ovat yleensä merkkejä voimakkaasta purentaongelmasta.
                Lievemmät purentaongelmat eivät välttämättä oireile
                hampaistossa, mutta voi ylläpitää esimerkiksi jatkuvia
                hartiaseudun ongelmia. Usein oireita ei osata yhdistää purentaan
                ja vaivojen todellinen syy jää selvittämättä.
              </Bullet>

              <Bullet first>
                <b>Hoito voi auttaa seuraaviin ongelmiin:</b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>
                  Päänsärky, migreeni, hampaiden narskuttelu
                </Bullet>
                <Bullet>Leuka- tai korvasärkyoireisiin</Bullet>
                <Bullet>
                  Nivelliikkeiden rajoittuneisuuteen ja kivuliaisuuteen
                </Bullet>
              </List>

              <Bullet first>
                Hoito tehdään tarvittaessa normaalin hieronnan yhteydessä tai
                omana käsittelynään. Hieronnassa käsitellään niska-hartiaseutu,
                kaulan ja pään alue sekä puremalihakset myös poskien
                sisäpinnoilta, joita käsitellessäni käytän
                kertakäyttöhansikkaita.
              </Bullet>
            </Palvelu>

            <Palvelu
              type="kylmälämpö"
              active={active}
              primary="Kylmä- ja lämpöhoidot"
              secondary="Kall- och varmbehandling"
              handleClick={this.handleClick}
            >
              <Bullet first>
                <b>Tehdään tarvittaessa hieronnan yhteydessä</b>
              </Bullet>
            </Palvelu>

            <Palvelu
              type="hoitosuunnitelma"
              active={active}
              primary="Hoitosuunnitelma"
              secondary="Vårdplan"
              handleClick={this.handleClick}
            >
              <Bullet first>
                Sisältää ryhdin- ja liikkuvuuden tutkinnan sekä ohjeistusta
                omatoimiseen harjoitteluun
              </Bullet>
              <Bullet>
                Testeillä saadaan tietoa lihastasapainosta (liikkuvuus ja
                kireydet), tämä mahdollistaa hoidollisesti keskittymisen heti
                ongelma-alueisiin
              </Bullet>
              <Bullet>
                Laajempi hoitosuunnitelma vaatii vähintään 4 hoitokertaa
              </Bullet>
            </Palvelu>

            <Palvelu
              type="rentoutumis"
              active={active}
              primary="Rentoutushieronnat"
              secondary="Avslappningsmassage"
              handleClick={this.handleClick}
            >
              <CardMedia
                className={classes.media_expansion}
                image={Kulho}
                disablePadding
              />

              <Bullet first>
                <b>
                  Ajara- Intialainen Jalkahieronta / Ayurvedinen refleksologia
                  (Padabhyanga)
                </b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>
                  Ayurvedinen jalkarefleksologia / - hieronta joka perustuu
                  perinteiseen intialaiseen hierontatyöskentelyyn. Hoidossa
                  käsitellään myös marmapisteitä
                </Bullet>
                <Bullet>
                  Hoito muodostuu erilaisista hankaus-, sively- ja
                  painelutekniikoista, joilla stimuloidaan lymfa- ja
                  verenkiertoa
                </Bullet>
                <Bullet>
                  Jalkahieronnassa käytetään pronssista kasamaljaa, jolla on
                  rauhoittava vaikutus
                </Bullet>
                <Bullet>
                  Käytän hieronnassa luonnon öljyjä tai perinteisesti käytettyä
                  seesamiöljyä, johon voidaan lisätä esim. laventelin- tai
                  piparmintun eteeristä öljyä.
                </Bullet>
                <Bullet>
                  Hoidon vaikutuksia: lievittää lihasjännityksiä, tehostaa
                  lymfa- ja verenkiertoa, rentouttaa ja vähentää stressiä,
                  auttaa unettomuuteen, ravitsee ihoa
                </Bullet>
                <Bullet>Hierotaan selinmakuulla</Bullet>
              </List>

              <Bullet first>
                <b>Intialainen päähieronta / Indisk huvudmassage</b>
              </Bullet>

              <List className={classes.nestedList}>
                <Bullet first>Rentoutukseen ja stressiin</Bullet>
                <Bullet>Unen laadun paranemiseen</Bullet>
                <Bullet>
                  Voi vaikuttaa päänsäryn ja migreenin oireisiin positiivisesti
                </Bullet>
                <Bullet>
                  Hierotaan selän yläosa, hartiat, niska, pää, kasvot ja
                  korvalehdet
                </Bullet>
                <Bullet>Hierotaan istuma-asennossa</Bullet>
              </List>
            </Palvelu>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Palvelut);
