import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MedialisGrid, MedialisGridContainer, Bullet } from './Components';
import Hidden from '@material-ui/core/Hidden';

import KhlLogo from './assets/khl_logo.jpg';
import Logo from './assets/logo.png';
import Hieronta from './assets/hieronta.jpg';

import { EmbeddedMedia } from './utils';

const styles = () => ({
  root: {},
  //card: {
  //},
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
});

class Etusivu extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media} image={Logo} />
              <CardContent>
                <Hidden mdDown>
                  <EmbeddedMedia image={Hieronta} height='300px' width='230px' float='right' />
                </Hidden>
                <Hidden lgUp smDown>
                  <EmbeddedMedia image={Hieronta} height='220px' width='170px' float='right' />
                </Hidden>
                <Hidden mdUp xsDown>
                  <EmbeddedMedia image={Hieronta} height='170px' width='130px' float='right' />
                </Hidden>
                <Typography gutterBottom variant="headline" component="h2">
                  Tervetuloa Hieronta Medialiksen kotisivuille!
                </Typography>
                <Typography paragraph>
                  <b>Hieronta Medialis</b> sijaitsee Siuntion keskustassa,
                  osoitteessa Sudenkaari 45 E 29, jossa teitä palvelee
                  koulutettu hieroja, urheiluhieroja <b>Monica Söderholm.</b>
                </Typography>
                <Hidden smUp>
                  <EmbeddedMedia image={Hieronta} height='300px' />
                </Hidden>
                <Typography paragraph>
                  Tarjoan asiakkailleni ammattitaitoista hoitavaa hierontaa
                  ”kuuntelevalla” otteella, kiireettömässä ilmapiirissä ja
                  asiakkaan ongelmiin perehtyen. Haluan työlläni paneutua
                  asiakkaan ongelmaan, enkä tee hierontoja liukuhihnatyylillä.
                  Vuosien myötä otteeni ovat monipuolistuneet ja hoito-otetta
                  säätelen napakasta rentouttavaan ongelmasta riippuen. Jokainen
                  asiakkaani on tärkeä ja ainutlaatuinen. Yksikään hoitopäivä ei
                  ole samanlainen ja se tekeekin työstäni antoisaa. Haluan
                  hoitaa asiakkaani ilolla ja inhimillisyydellä. Teen myös
                  rentouttavia hierontoja.
                </Typography>
                <div style={{ clear: 'right' }} />
              </CardContent>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={KhlLogo}
                component="a"
                href="http://www.khl.fi/"
              />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Koulutettu hieroja
                </Typography>
                <Typography paragraph>
                  Koulutettu hieroja on nimikesuojattu ammattinimike, jota saa
                  käyttää ainoastaan hierojan ammattitutkinnon suorittaneet
                  henkilöt.
                </Typography>
                <Typography>
                  Koulutettu hieroja on terveydenhuollon ammattihenkilö, joista
                  Sosiaali- ja terveysalan lupa- ja valvontavirasto (VALVIRA)
                  ylläpitää keskusrekisteriä.
                </Typography>
              </CardContent>

              <CardActions>
                <Button color="primary" component="a" href="http://www.khl.fi/">
                  www.khl.fi
                </Button>
                <Button
                  color="primary"
                  component="a"
                  href="https://julkiterhikki.valvira.fi/"
                >
                  julkiterhikki.valvira.fi
                </Button>
              </CardActions>
            </Card>
          </MedialisGrid>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Hierojan EAT
                </Typography>
                <Typography paragraph>
                  Olen suorittanut hyväksytysti hierojan EAT eli hierojan
                  erikoisammattitutkinnon Pirkanmaan urheiluhierojakoulussa v.
                  2018. Koulutus on hieronta-alalla työskenteleville
                  koulutetuille hierojille tarkoitettu syventävä, laadukas ja
                  kattava jatkokoulutus tuki- ja liikuntaelinten toiminnasta
                  sekä siihen liittyvästä tutkimisesta ja hoidoista. Koulutus on
                  kestoltaan noin kaksi vuotta.
                </Typography>

                <Typography paragraph>
                  Opintokokonaisuuden hyväksytysti läpikäyneet hierojat ovat
                  oikeutettuja käyttämään lyhennettä Hierojan EAT. Koulutus
                  syvensi huomattavasti tiedollista ja taidollista osaamistani
                  ja on tuonut monipuolisuutta asiakkaiden hoitoon ja oman työni
                  mielekkyyteen.
                </Typography>

                <Typography paragraph>
                  <b>Erikoistumisopinnoissani suoritin seuraavat osiot:</b>
                </Typography>

                <Bullet>Nivelten liikkuvuuden edistäminen</Bullet>
                <Bullet>Kivun hoito</Bullet>
                <Bullet>Urheilijan lihashuolto</Bullet>
                <Bullet>Hierontatyössä asiantuntijana toimiminen</Bullet>
              </CardContent>
            </Card>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Etusivu);
