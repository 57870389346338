import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { MedialisGrid, MedialisGridContainer } from './Components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MapIcon from '@material-ui/icons/Directions';

import House from './assets/talo.jpg';
import Monica from './assets/monica.jpg';

const avatarSize = 96;

const styles = () => ({
  root: {},
  //card: {
  //},
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
  avatar: {
    width: avatarSize,
    height: avatarSize,
  },
});

class Yhteystiedot extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media_cover} image={House} />
              <CardContent>
                <Typography variant="headline" component="h2">
                  Yhteystiedot / Kontaktuppgifter
                </Typography>
              </CardContent>

              <CardHeader
                avatar={
                  <Avatar
                    aria-label="Monica Söderholm"
                    className={classes.avatar}
                    src={Monica}
                  />
                }
                title="Monica Söderholm"
                subheader="Hieronta Medialis"
              />

              <List>
                <ListItem button href="tel:+358447489191" component="a">
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="044 748 9191" />
                </ListItem>

                <ListItem
                  button
                  href="mailto:monica.soderholm411@gmail.com"
                  component="a"
                >
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="monica.soderholm411@gmail.com" />
                </ListItem>

                <ListItem
                  button
                  href="https://goo.gl/maps/CMu87r65AX92"
                  target="_blank"
                  component="a"
                >
                  <ListItemIcon>
                    <MapIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sudenkaari 45 E 29, 02580 Siuntio" />
                </ListItem>

                <ListItem
                  button
                  href="https://www.facebook.com/Hieronta-Medialis-193298231434067/"
                  target="_blank"
                  component="a"
                >
                  <ListItemIcon>
                    <SvgIcon viewBox="0 0 266.893 266.895">
                      <path
                        id="Blue_1_"
                        fill="#757575"
                        d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812
                        c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225
                        H248.082z"
                      />
                      <path
                        id="f"
                        fill="#FFFFFF"
                        d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935
                        l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585
                        v99.803H182.409z"
                      />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText primary="Hieronta Medialis Facebook" />
                </ListItem>
              </List>

              <CardContent>
                <Typography paragraph>
                  <b>HUOM: Hoitojen aikana en voi vastata puheluihin</b>, mutta
                  otan yhteyttä mahdollisimman pian. Kysy myös ilta-aikoja!
                </Typography>

                <Typography>
                  <b>
                    OBS: Då jag är upptagen med en kund kan jag inte svara i
                    telefon
                  </b>, men jag tar kontakt så fort som möjligt. Fråga även
                  efter kvällstider!
                </Typography>
              </CardContent>
            </Card>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Yhteystiedot);
