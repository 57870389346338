import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { MedialisGrid, MedialisGridContainer } from './Components';

const styles = () => ({
  root: {
  },
  fbContainer: {
    textAlign: 'center'
  },
  card: {
    height: '100%',
    //width: "100%"
  },
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
});

class Ajankohtaista extends React.Component {
  state = {
    hieronta: false
  };

  handleClick = type => () => {
    console.log(type);
    this.setState(oldState => ({ [type]: !oldState[type] }));
  }

  componentDidMount = () => {
    // Hacks to work around FB plugin not loading upon re-entering page
    window.FB && window.FB.XFBML.parse();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <div className={classes.fbContainer}>
              <div id="fb-root"></div>
              <div className="fb-page" data-href="https://www.facebook.com/Hieronta-Medialis-193298231434067/" data-tabs="timeline" data-width="500" data-height="3000" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Hieronta-Medialis-193298231434067/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Hieronta-Medialis-193298231434067/">Hieronta Medialis Facebook</a></blockquote></div>
            </div>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Ajankohtaista);
