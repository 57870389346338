import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import brown from '@material-ui/core/colors/brown';

import { HashRouter as Router, Route } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import ServiceIcon from '@material-ui/icons/RoomService';
import PricingIcon from '@material-ui/icons/EuroSymbol';
import ContactIcon from '@material-ui/icons/ContactPhone';
import RajoituksetIcon from '@material-ui/icons/PanTool';
import DateIcon from '@material-ui/icons/DateRange';

import Sidebar, { drawerWidth } from './Sidebar';
import Header from './Header';
import Etusivu from './Etusivu';
import Palvelut from './Palvelut';
import Rajoitukset from './Rajoitukset';
import Hinnasto from './Hinnasto';
import Yhteystiedot from './Yhteystiedot';
import Ajankohtaista from './Ajankohtaista';
import Svenska from './Svenska';

import BG from './assets/bg.jpg';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Suomeksi',
    subtitle: 'Etusivu',
    icon: props => (
      <SvgIcon viewBox="0 0 640 480" {...props}>
        <path fill="#fff" d="M0 0h640v480H0z" />
        <path fill="#003580" d="M0 174.5h640v131H0z" />
        <path fill="#003580" d="M175.5 0h130.9v480h-131z" />
      </SvgIcon>
    ),
    component: Etusivu,
  },
  {
    path: '/svenska',
    title: 'På Svenska',
    subtitle: 'Framsida',
    icon: props => (
      <SvgIcon viewBox="0 0 560 712" {...props}>
        <path
          fill="#006aa7"
          d="M-121.1.3h256v204.8h-256zm0 306.9h256V512h-256z"
        />
        <path fill="#fecc00" d="M-121.1 205h256v102.4h-256z" />
        <path fill="#fecc00" d="M133.8 0h102.4v512H133.8z" />
        <path fill="#fecc00" d="M233 205h460.8v102.4H233z" />
        <path
          fill="#006aa7"
          d="M236.2 307.2H697V512H236.2zm0-306.9H697v204.8H236.2z"
        />
      </SvgIcon>
    ),
    component: Svenska,
    divider: true,
  },
  {
    path: '/palvelut',
    title: 'Palvelut',
    subtitle: 'Tjänster',
    icon: ServiceIcon,
    component: Palvelut,
  },
  {
    path: '/rajoitukset',
    title: 'Rajoitukset',
    subtitle: 'Begränsningar',
    icon: RajoituksetIcon,
    component: Rajoitukset,
  },
  {
    path: '/hinnasto',
    title: 'Hinnasto',
    subtitle: 'Prislista',
    icon: PricingIcon,
    component: Hinnasto,
  },
  {
    path: '/ajankohtaista',
    title: 'Ajankohtaista',
    subtitle: 'Aktuellt',
    icon: DateIcon,
    component: Ajankohtaista,
  },
  {
    path: '/yhteystiedot',
    title: 'Yhteystiedot',
    subtitle: 'Kontaktuppgifter',
    icon: ContactIcon,
    component: Yhteystiedot,
  },
];

const theme = createMuiTheme({
  palette: {
    primary: brown,
  },
});

// TODO: needed?
const styles = theme => ({
  root: {
    flexGrow: 1,
    //display: 'flex',
  },
  background: {
    height: '100vh',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundImage: `url(${BG})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
  container: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  toolbar: theme.mixins.toolbar,
  appContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
});

class App extends React.Component {
  state = { drawerOpen: false };

  toggleDrawer = drawerOpen => () => this.setState({ drawerOpen });

  render() {
    const { classes } = this.props;

    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <Header routes={routes} toggleDrawer={this.toggleDrawer} />
            <Sidebar
              routes={routes}
              toggleDrawer={this.toggleDrawer}
              open={this.state.drawerOpen}
            />

            <div className={classes.container}>
              <div className={classes.background} />
              <div className={classes.toolbar} />
              <div className={classes.appContent}>
                <Grid container spacing={0}>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default withStyles(styles)(App);
