import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { MedialisGrid, MedialisGridContainer, Bullet } from './Components';

import List from '@material-ui/core/List';

import RajoituksetImage from './assets/rajoitukset.jpg';

const styles = theme => ({
  root: {},
  //card: {
  //},
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
  nestedList: {
    paddingLeft: theme.spacing.unit * 2,
  },
});

class Rajoitukset extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media_cover}
                image={RajoituksetImage}
              />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Hieronnan rajoitukset / Massagebegränsningar
                </Typography>

                <Bullet first>
                  <b>
                    Näissä tapauksissa keskustele hierojan kanssa ennen
                    ajanvarausta:
                  </b>
                </Bullet>

                <List className={classes.nestedList}>
                  <Bullet first>
                    Kuumetta tai olet vahvojen särkylääkkeiden vaikutuksen
                    alainen
                  </Bullet>
                  <Bullet>
                    Hieronta-alueella suonikohjuja tai verisuoniproteesi
                  </Bullet>
                  <Bullet>
                    Hieronta-alueella tuore vamma (lihaksen/jänteen revähdys,
                    venähdys tai katkeaminen, luunmurtuma, ruhje, palovamma,
                    paleltuma tms.)
                  </Bullet>
                  <Bullet>
                    Hieronta-alueen iho infektoitunut, haavainen tai
                    huonokuntoinen
                  </Bullet>
                  <Bullet>
                    Hieronta-alueella tulehdus (lihas, jänne, luukalvo,
                    limapussi)
                  </Bullet>
                  <Bullet>Sydänsairaus</Bullet>
                  <Bullet>Verenvuototauti (hemofilia)</Bullet>
                  <Bullet>
                    Epätasapainossa oleva perussairaus (verenpaine, epilepsia
                    tms.)
                  </Bullet>
                  <Bullet>Akuutti nivelreuma</Bullet>
                  <Bullet>Pahanlaatuinen kasvain tai tutkimaton kyhmy</Bullet>
                </List>

                <Bullet>
                  <b>
                    Keskustele aina lääkärisi kanssa, jos olet epävarma
                    hieronnan sopivuudesta.
                  </b>
                </Bullet>
                <Bullet>
                  <b>
                    Hierontaan ei saa tulla päihteiden vaikutuksen alaisena.
                  </b>
                </Bullet>

                <Bullet>
                  <b>Intialaisessa jalkahieronnassa:</b>
                </Bullet>

                <List className={classes.nestedList}>
                  <Bullet first>Suonikohjut (jalassa)</Bullet>
                  <Bullet>Suonitulehdukset</Bullet>
                  <Bullet>Verisuonitukos</Bullet>
                  <Bullet>Vakava mustelma</Bullet>
                  <Bullet>Vakava nilkan nyrjähdys</Bullet>
                  <Bullet>Ihotulehdus</Bullet>
                  <Bullet>Sienitulehdus</Bullet>
                  <Bullet>Syylät</Bullet>
                  <Bullet>Avohaava / märkivä haava</Bullet>
                </List>
              </CardContent>
            </Card>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Rajoitukset);
