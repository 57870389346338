import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

export const MedialisGrid = props => (
  <Grid container item xs={12} spacing={2} justify="center">
    <Grid item xs={12} sm={8} lg={6} {...props} />
  </Grid>
);

export const MedialisGridContainer = props => (
  <Grid container item spacing={2} {...props} />
);

export class Bullet extends React.Component {
  render() {
    const { children, first, ...props } = this.props;
    return (
      <React.Fragment>
        {!first && <Divider />}
        <ListItem>
          <Typography>{children}</Typography>
          {/* <ListItemText {...props}>{children}</ListItemText> */}
        </ListItem>
      </React.Fragment>
    );
  }
}
