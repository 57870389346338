import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent  from '@material-ui/core/CardContent';
import CardActions  from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MedialisGrid, MedialisGridContainer, Bullet } from './Components';
import Hidden from '@material-ui/core/Hidden';
import { EmbeddedMedia } from './utils';

import KhlLogo from './assets/khl_logo.jpg';
import Logo from './assets/logo.png';
import Hieronta from './assets/hieronta.jpg';

const styles = () => ({
  card: {
    width: '100%',
  },
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
});

class Svenska extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media} image={Logo} />
              <CardContent>
                <Hidden mdDown>
                  <EmbeddedMedia image={Hieronta} height='300px' width='230px' float='right' />
                </Hidden>
                <Hidden lgUp smDown>
                  <EmbeddedMedia image={Hieronta} height='220px' width='170px' float='right' />
                </Hidden>
                <Hidden mdUp xsDown>
                  <EmbeddedMedia image={Hieronta} height='170px' width='130px' float='right' />
                </Hidden>
                <Typography gutterBottom variant="headline" component="h2">
                  Välkommen till Hieronta Medialis hemsidor!
                </Typography>
                <Typography paragraph>
                  <b>Hieronta Medialis</b> ligger vid Vargsvängen 45 E 29, 02580
                  Sjundeå, där utbildad massör, idrottsmassör{' '}
                  <b>Monica Söderholm</b> betjänar er.
                </Typography>
                <Hidden smUp>
                  <EmbeddedMedia image={Hieronta} height='300px' />
                </Hidden>
                <div style={{ clear: 'right' }} />
              </CardContent>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={KhlLogo}
                component="a"
                href="http://www.khl.fi/"
              />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Utbildad massör
                </Typography>
                <Typography paragraph>
                  Yrkesbeteckningen "utbildad massör" kan inte användas utan
                  tillstånd. De yrkesutbildade personer som har avlagt den
                  officiella massörexamen kan ansöka om rätten att använda den
                  skyddade yrkesbeteckningen utbildad massör.
                </Typography>
                <Typography>
                  Skyddade yrkesbeteckningar får användas endast av de
                  yrkesutbildade personer som av VALVIRA har införts i
                  centralregistret över yrkesutbildade personer inom hälso- och
                  sjukvården.
                </Typography>
              </CardContent>

              <CardActions>
                <Button color="primary" component="a" href="http://www.khl.fi/">
                  www.khl.fi
                </Button>
                <Button
                  color="primary"
                  component="a"
                  href="https://julkiterhikki.valvira.fi/"
                >
                  julkiterhikki.valvira.fi
                </Button>
              </CardActions>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Hierojan EAT
                </Typography>
                <Typography paragraph>
                  Jag har studerat fördjupande specialutbildning i 2 år vid
                  Pirkanmaan urheiluhierojakoulu i Tammerfors. Examen är godkänd
                  februari 2018.
                </Typography>
                <Typography paragraph>
                  <b>Specialutbildningen innehöll följande element:</b>
                </Typography>
                <Bullet>Förbättring av ledrörlighet</Bullet>
                <Bullet>Smärtterapi</Bullet>
                <Bullet>Idrottsmuskelvård</Bullet>
                <Bullet>Sakkunnighet inom massagearbete</Bullet>
              </CardContent>
            </Card>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Svenska);
