import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { MedialisGrid, MedialisGridContainer } from './Components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Kulho from './assets/kulho.jpg';
import Flower from './assets/blomma.jpg';
import Kinesio from './assets/kinesio.jpg';

const styles = () => ({
  root: {},
  //card: {
  //},
  media: {
    height: 200,
    backgroundSize: 'contain',
  },
  media_cover: {
    height: 200,
    backgroundSize: 'cover',
  },
  warn: {
    color: '#f66',
  },
});

class Palvelut extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MedialisGridContainer>
          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media_cover} image={Flower} />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Hinnasto / Prislista
                </Typography>

                <List dense>
                  <ListItem>
                    <ListItemText primary="30 min" secondary="35 €" />
                    <ListItemText primary="45 min" secondary="45 €" />
                    <ListItemText primary="60 min" secondary="50 €" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="75 min" secondary="59 €" />
                    <ListItemText primary="90 min" secondary="70 €" />
                    <ListItemText primary="120 min" secondary="100 €" />
                  </ListItem>
                  <ListItem>
                    <ListItemText secondary="(Maksu käteisellä / Kontant betalning)" />
                  </ListItem>
                  <ListItem>
                    <Typography className={classes.warn}>
                      HUOM! Muistathan esteen sattuessa peruuttaa sovitun ajan,
                      viimeistään saman päivän aamuna klo 9.00 mennessä
                      soittamalla tai tekstiviestillä. Myöhemmin perutusta tai
                      peruuttamattomasta ajasta veloitetaan varatun hieronnan
                      hinta.
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media_cover} image={Kinesio} />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Kinesioteippaus / Kinesiotejpning
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Hieronnan yhteydessä / I samband med massage"
                      secondary="0 €"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Laaja teippaus / Omfattande tejpning"
                      secondary="5 €"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Erikseen / Skilt" secondary="20 €" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </MedialisGrid>

          <MedialisGrid>
            <Card className={classes.card}>
              <CardMedia className={classes.media_cover} image={Kulho} />
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Rentoutushieronta / Avslappningsmassage
                </Typography>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Ajara-intialainen jalkahieronta / Ajara-indisk fotmassage"
                      secondary="50 € (55 min)"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Intialainen päähieronta / Indisk huvudmassage"
                      secondary="35 € (30 min)"
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </MedialisGrid>
        </MedialisGridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Palvelut);
